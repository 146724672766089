<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CCol col="6" style="font-size: 25px">تعديل الفئة</CCol>
        </CCardHeader>
        <CCardBody>
          <div>
            <div class="row">
              <div class="col-md-6">
                <form @submit.prevent="UpdateCategory">
                  <div class="form-group">
                    <label style="padding: 5px"
                      >Name <span class="star">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="category.name"
                      placeholder="الاسم باللغة الإنجليزية"
                      required
                    />
                    <div v-if(errors.name) id="validateName" class="invalid-feedback">
                      {{errors.name}}
                    </div>
                  </div>

                  <div class="form-group">
                    <label style="padding: 5px"
                      >الاسم <span class="star">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="category.name_ar"
                      placeholder="الاسم باللغة العربية"
                      required
                    />
                  </div>

                  <div class="form-group">
                    <label style="padding: 5px">الصورة القديمة</label>
                    <img
                      v-bind:src="category.image_full_path"
                      style="height: 100px; width: 100px"
                    />
                  </div>
                  <div class="form-group">
                    <label style="padding: 5px">الصورة الجديدة</label>
                    <input type="file" id="image" v-on:change="onFileChange" />
                    <div v-if(errors.image) id="validateName" class="invalid-feedback">
                      {{errors.image}}
                    </div>
                    <small class="form-text text-muted">حجم الصورة يجب ان لايتجاوز 5 ميقا</small>
                  </div>

                  <button type="submit" class="btn btn-success">حفظ</button>
                  &emsp;
                  <CButton color="warning" @click="goBack">رجوع</CButton>
                </form>
              </div>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import swal from "sweetalert";
export default {
  name: "EditCategory",
  data() {
    return {
      category: [],
      image: "",
      errors: []
    };
  },
  created() {
    console.log(this.$route.params.id);
    this.$http
      .get(`${process.env.VUE_APP_URL}categories/${this.$route.params.id}/edit`)
      .then((response) => {
        console.log(response.data.data);
        this.category = response.data.data;
      });
  },
  methods: {
    onFileChange(e) {
      this.category.image = e.target.files[0];
      this.image = e.target.files[0];
      console.log(this.image);
    },
    UpdateCategory(e) {
      let currentObj = this;
      let formData = new FormData();
      if (this.image) {
        if (this.image.size > 5000000) {
          e.preventDefault();
          this.errors.push('image')
          this.errors.image = 'حجم الصورة تجاوز 5 ميقا'
          swal({ title: 'هناك خطأ في ادخال البيانات في حقل او اكثر', buttons: "تم" });
          return 0;
        } else {
          formData.append("image", this.image);
        }
      }
      formData.append("name", this.category.name);
      formData.append("name_ar", this.category.name_ar);
      formData.append("_method", "PUT");
      console.log(this.category.name);
      console.log(formData);
      this.$http
        .post(
          `${process.env.VUE_APP_URL}categories/${this.$route.params.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            },
          }
        )
        .then((response) => {
          if (!response.data.error) {
            swal({ title: response.data.message, buttons: "تم" });
            currentObj.$router.push({ path: "/categories" });
          } else {
            currentObj.errors = response.data.data;
          }
        });
    },
    goBack() {
      this.categoriesOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/categories" });
    },
  },
};
</script>